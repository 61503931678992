import { TrackingEvent } from 'client/tracking/event';
import { CommonMap } from 'client/tracking/maps/common';
import { TrackingConstant } from 'client/tracking/constant';

import { SuggestedVehiclePricingCarouselMap } from 'site-modules/shared/components/suggested-vehicle-pricing-carousel/tracking';
import { HomepageAdExtensionMap } from 'client/tracking/maps/common/homepage-ad-extension';

import { generateTrackingIds } from 'site-modules/shared/utils/make-model-zip';
import { getSearchByModuleTrackingMap } from 'client/tracking/maps/common/search-by-module';
import { GlobalSearchMap } from 'client/tracking/maps/global-search';

export const MMZ_TRACKING_IDS = generateTrackingIds('home_page');

const map = {
  home_page_inventory_select_tab: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.TAB_SELECT
  ),

  [MMZ_TRACKING_IDS.makeSelectTrackingId]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.MAKE_SELECT
  ),

  [MMZ_TRACKING_IDS.modelSelectTrackingId]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.MODEL_SELECT
  ),

  [MMZ_TRACKING_IDS.submitTrackingId]: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MMY
  ),
  ...getSearchByModuleTrackingMap(MMZ_TRACKING_IDS),

  home_page_trending: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.FILTER_SEARCH_RESULT
  ),

  home_page_mmy_submit_make: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MAKE
  ),

  home_page_mmy_submit_model: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MODEL
  ),

  home_page_type_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TYPE_SELECT,
    TrackingConstant.ACTION_TYPE_SELECT
  ),

  home_page_vaporware_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_VAPORWARE
  ),

  view_vehicle: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.VIEW_VEHICLE),

  see_all_saved: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.SEE_ALL_SAVED),

  see_all_used: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.SEE_ALL_USED),

  see_all_new: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.SEE_ALL_NEW),

  start_shopping: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.START_SHOPPING),

  home_value_prop_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_TOOL_SELECT
  ),

  home_shop_now_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.VIEW_MATCH_DETAILS
  ),

  home_vehicle_preference_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.INPUT_PREFERENCE
  ),

  home_extra_links_show_all: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_ALL
  ),

  home_page_featured_spotlight_ad_click_out(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_WEBSITE_CLICKOUT,
      TrackingConstant.OEM_SITE_CLICKOUT,
      options
    );
  },

  home_page_featured_spotlight_ad_click_in(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_VIEW_CONTENT,
      TrackingConstant.ACTION_VIEW_CONTENT,
      options
    );
  },

  click_appraisal_cta: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.APPRAISE_YOUR_CAR
  ),

  click_article_cta: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.VIEW_ARTICLE),

  select_make_dropdown: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.MAKE_SELECT
  ),

  select_model_dropdown: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.MODEL_SELECT
  ),

  select_year_dropdown: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.YEAR_SELECT
  ),

  home_hero_seo_link: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  home_hero_appraisal_link: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.ACTION_APPRAISE_VEHICLE
  ),

  home_content_feature_article_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_TOOL_SELECT
  ),

  high_impact_click_in: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  high_impact_click_out: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.OEM_SITE_CLICKOUT
  ),

  high_impact_disclaimer_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  high_impact_disclaimer_close: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.NAVIGATION_CLOSE
  ),

  return_dashboard_view_vin_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_VIN_DETAILS,
    TrackingConstant.ACTION_VIEW_VIN_DETAILS
  ),

  return_dashboard_mmy_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SELECT_MAKE_MODEL
  ),

  appraiser_retarget_continue_appraisal: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_CONTINUE_APPRAISAL
  ),

  appraiser_retarget_view_offer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_VIEW_APPRAISAL
  ),

  appraiser_retarget_renew_offer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_RENEW_OFFER
  ),

  editors_choice_link: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_TOOL_SELECT
  ),

  shop_by_brands_link: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.ACTION_MAKE_SELECT
  ),

  shop_by_brands_all_link: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.ACTION_TOOL_SELECT
  ),

  search_more_cta: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.ACTION_TOOL_SELECT
  ),

  ted_review_deal_cta: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SEE_ALL_SAVED
  ),

  ted_loan_cta: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
    TrackingConstant.VIEW_LOAN_DETAILS
  ),

  ted_lease_cta: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
    TrackingConstant.VIEW_LEASE_DETAILS
  ),

  buying_guide_module_cta: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  deals_module_cta: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.SUBMIT_MMY),

  vehicle_type_module_cta: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  vehicle_testing_team_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  inventory_remove_filter_search_result: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.FILTER_SEARCH_RESULT_REMOVE
  ),

  drawer_close: TrackingEvent.actionCompleted(TrackingConstant.ACTION_RESET_NAVIGATION, TrackingConstant.CLOSE_DRAWER),

  view_filters: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.VIEW_FILTERS),

  open_financing_app: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_FILL_FORM,
    TrackingConstant.SUBACTION_OPEN_FINANCING_APP
  ),
};

export const TrackingMap = {
  ...CommonMap,
  ...map,
  ...SuggestedVehiclePricingCarouselMap,
  ...HomepageAdExtensionMap,
  ...GlobalSearchMap,
};
