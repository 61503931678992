import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import classnames from 'classnames';

import { getTitleWithNoOrphans } from 'site-modules/shared/utils/get-title-with-no-orphans';

import { Link } from 'site-modules/shared/components/link/link';
import { SemanticSearch } from 'site-modules/shared/components/inventory/semantic-search/semantic-search';
import { GlobalSearch } from 'site-modules/shared/components/inventory/global-search/global-search';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ClippedContainer } from 'site-modules/shared/components/clipped-container/clipped-container';
import convexMaskMobile from 'site-modules/shared/components/clipped-container/mask-library/home-header-mobile';
import convexMaskDesktop from 'site-modules/shared/components/clipped-container/mask-library/convex-header';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';

import './home-hero-inventory.scss';

const BUTTON_DATA = [
  {
    id: 'used_btn',
    label: 'Shop Used',
    url: '/used-all/',
  },
  {
    id: 'new_btn',
    label: 'Shop New',
    url: '/new-cars-for-sale/',
  },
  {
    id: 'appraise_btn',
    label: 'Appraise My Car',
    url: '/appraisal/',
  },
];

export function HomeHeroInventory({ trackingParent, isMobile, className, isShop2709Enabled, withEnterSubmitDisabled }) {
  return (
    <FeatureFlag name="disable-homepage-llm">
      {disableLlm => (
        <div className={classnames('home-hero-inventory pos-r p-0', className)} data-tracking-parent={trackingParent}>
          <div className="pos-r px-1">
            <ClippedContainer
              containerClasses={classnames('homepage-gradient d-flex pos-a top-0 left-0 right-0', {
                mobile: isMobile,
                'semantic-search-disabled': disableLlm,
              })}
              mask={isMobile ? convexMaskMobile : convexMaskDesktop}
            />
            <ContentFragment
              componentToUse="h1"
              classes={classnames('text-white text-center pos-r pt-1_5 pb-1_5 mb-0 title', {
                'pt-md-2_5': disableLlm,
                'pt-md-3': !disableLlm,
              })}
            >
              {getTitleWithNoOrphans('Let’s find your perfect car')}
            </ContentFragment>
            {!disableLlm &&
              (isShop2709Enabled ? (
                <GlobalSearch isMobile={isMobile} withEnterSubmitDisabled={withEnterSubmitDisabled} />
              ) : (
                <div className="semantic-search-container pos-r w-100 d-flex justify-content-center mb-1_5">
                  <div className={classnames('semantic-search-form-border', { 'w-100': isMobile })}>
                    <SemanticSearch
                      isMobile={isMobile}
                      inputMinHeight={56}
                      disableDrawerCloseOnSubmit
                      searchFormClassName="semantic-search-form pos-r"
                      inputClassName="semantic-search-input size-16"
                      submitButtonClassName="semantic-search-submit-button pos-a d-flex justify-content-center align-items-center bg-green-50"
                      submitButtonContent={
                        <span
                          className={classnames('icon-magic-search icon text-white', {
                            'size-16': !isMobile,
                            'size-20': isMobile,
                          })}
                          aria-hidden
                        />
                      }
                      isHome
                    />
                  </div>
                </div>
              ))}
          </div>
          <div
            className={classnames('d-flex align-items-center justify-content-center buttons', {
              mobile: isMobile,
              desktop: !isMobile,
              'mb-2_5': disableLlm,
              'mb-1_5': !disableLlm,
            })}
            data-tracking-parent="edm-entry-inventory-search"
          >
            {BUTTON_DATA.map(({ id, label, url }) => (
              <Button
                key={id}
                tag={Link}
                color="outline-blue-50"
                to={url}
                className={classnames(
                  'pos-r d-flex align-items-center text-transform-none inventory-button px-0_5 py-0 rounded-8 bg-white',
                  {
                    medium: isMobile,
                    'size-16': !isMobile,
                  }
                )}
                data-tracking-id="home_page_inventory_select_tab"
              >
                {label}
              </Button>
            ))}
          </div>
        </div>
      )}
    </FeatureFlag>
  );
}

HomeHeroInventory.propTypes = {
  trackingParent: PropTypes.string,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  isShop2709Enabled: PropTypes.bool,
  withEnterSubmitDisabled: PropTypes.bool,
};

HomeHeroInventory.defaultProps = {
  trackingParent: null,
  isMobile: false,
  className: null,
  isShop2709Enabled: false,
  withEnterSubmitDisabled: false,
};
