import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { noop } from 'lodash';
import { randomInt } from 'client/utils/seed-randomizers';
import { PUB_STATES } from 'client/constants/pub-states';
import { FieldError } from 'client/site-modules/shared/components/field-error/field-error';
import { MakeDropdown } from './make-dropdown/make-dropdown';
import { StylesDropdown } from './styles-dropdown/styles-dropdown';
import { ModelDropdown } from './model-dropdown/model-dropdown';
import { SubmodelDropdown } from './submodel-dropdown/submodel-dropdown';
import { YearDropdown } from './year-dropdown/year-dropdown';
import { MostPopularStylesDropdown } from './most-popular-styles-dropdown/most-popular-styles-dropdown';

const DEFAULT_YMMS_ERROR_MESSAGES = {
  make: 'Please select a make',
  model: 'Please select a model',
  submodel: 'Please select a model',
  year: 'Please select a year',
  style: 'Please select a style',
};

const PropEntities = {
  Col: {
    PropTypes: PropTypes.shape({
      xs: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number,
    }),
    DefaultProps: { xs: 12, md: null, lg: null, xl: null },
  },
};

export class YmmsDropdowns extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    dropdownClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    formGroupClassName: PropTypes.string,
    prepopulatedVehicle: PropTypes.shape({
      submodels: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      makeSlug: PropTypes.string,
      modelSlug: PropTypes.string,
      name: PropTypes.string,
    }),
    dropdownPlaceholders: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.string,
      style: PropTypes.string,
    }),
    dropdownLabels: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.string,
      style: PropTypes.string,
    }),
    dropdownTrackingIds: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.string,
      style: PropTypes.string,
    }),
    errorMessages: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.string,
      style: PropTypes.string,
    }),
    displayError: PropTypes.shape({
      make: PropTypes.bool,
      model: PropTypes.bool,
      year: PropTypes.bool,
      style: PropTypes.bool,
    }),
    useStyledSelects: PropTypes.bool,
    hasModelsDropdown: PropTypes.bool,
    hasSubmodelsDropdown: PropTypes.bool,
    hasStylesDropdown: PropTypes.bool,
    hideLabels: PropTypes.bool,
    useMostPopularStylesDropdown: PropTypes.bool,
    rowClassName: PropTypes.string,
    colClassName: PropTypes.string,
    dropdownColSize: PropTypes.shape({
      year: PropEntities.Col.PropTypes,
      make: PropEntities.Col.PropTypes,
      model: PropEntities.Col.PropTypes,
      submodel: PropEntities.Col.PropTypes,
      style: PropEntities.Col.PropTypes,
    }),
    suffix: PropTypes.string,
    pubStates: PropTypes.arrayOf(PropTypes.string),
    isFormControlErrorHighlighted: PropTypes.bool,
    useUniqId: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    className: null,
    dropdownClassName: null,
    onChange: noop,
    onFocus: noop,
    onSubmit: undefined,
    labelClassName: 'dropdown-label label text-gray-dark mb-0_5',
    formGroupClassName: 'mb-0',
    prepopulatedVehicle: {},
    dropdownLabels: {},
    dropdownTrackingIds: {},
    dropdownPlaceholders: {
      make: 'Select',
      model: 'Select',
      submodel: 'Select',
      year: 'Select',
      style: 'Select',
    },
    errorMessages: DEFAULT_YMMS_ERROR_MESSAGES,
    displayError: {},
    useStyledSelects: false,
    hasModelsDropdown: false,
    hasSubmodelsDropdown: true,
    hasStylesDropdown: true,
    hideLabels: false,
    useMostPopularStylesDropdown: false,
    rowClassName: null,
    colClassName: null,
    dropdownColSize: {
      year: PropEntities.Col.DefaultProps,
      make: PropEntities.Col.DefaultProps,
      model: PropEntities.Col.DefaultProps,
      submodel: PropEntities.Col.DefaultProps,
      style: PropEntities.Col.DefaultProps,
    },
    suffix: '',
    pubStates: [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED],
    isFormControlErrorHighlighted: undefined,
    useUniqId: false,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.uniqId = this.props.useUniqId ? randomInt() : '';

    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    const { year, makeSlug, modelSlug, id, name, submodels } = props.prepopulatedVehicle;

    if (year && state.prevPrepopulatedVehicle !== props.prepopulatedVehicle) {
      const newState = {
        year: year.toString(),
        make: makeSlug,
        model: modelSlug,
        style: { id, name },
      };

      if (submodels) {
        newState.submodel = {
          niceId: submodels.slug,
          name: submodels.name,
        };
      }

      newState.prevPrepopulatedVehicle = props.prepopulatedVehicle;

      return newState;
    }

    return {};
  }

  onChange = () => {
    const { make, model, submodel, style, year } = this.state;
    this.props.onChange({ make, model, submodel, style, year });
  };

  setMake = ({ niceName }) => {
    this.setState(
      {
        make: niceName,
        model: null,
        submodel: null,
        style: null,
      },
      this.onChange
    );
  };

  setModel = ({ niceId }) => {
    this.setState(
      {
        model: niceId,
        submodel: null,
        style: null,
      },
      this.onChange
    );
  };

  setSubmodel = ({ niceId, name, modelNiceId }) => {
    this.setState(
      {
        submodel: {
          niceId,
          name,
        },
        model: modelNiceId,
        style: null,
      },
      this.onChange
    );
  };

  setYear = ({ year }) => {
    this.setState(
      {
        year,
        make: null,
        submodel: null,
        style: null,
        model: null,
      },
      this.onChange
    );
  };

  setStyle = style => {
    this.setState(
      {
        style,
      },
      this.onChange
    );
  };

  render() {
    const {
      className,
      dropdownClassName,
      labelClassName,
      formGroupClassName,
      children,
      dropdownPlaceholders,
      dropdownLabels,
      dropdownTrackingIds,
      useStyledSelects,
      hasModelsDropdown,
      hasSubmodelsDropdown,
      hasStylesDropdown,
      displayError,
      errorMessages,
      hideLabels,
      useMostPopularStylesDropdown,
      rowClassName,
      colClassName,
      dropdownColSize,
      suffix,
      pubStates,
      onSubmit,
      isFormControlErrorHighlighted,
      disabled,
    } = this.props;
    const { make, model, submodel, style, year } = this.state;

    const StylesDropDownComponent = useMostPopularStylesDropdown ? MostPopularStylesDropdown : StylesDropdown;
    return (
      <Form autoComplete="off" className={className} onSubmit={onSubmit}>
        <Row className={rowClassName}>
          <Col {...dropdownColSize.year} className={colClassName}>
            <FormGroup
              className={classnames(formGroupClassName, 'year-select-form-group', {
                'has-danger': isFormControlErrorHighlighted && displayError.year,
              })}
            >
              <YearDropdown
                name="select-year"
                toggle={dropdownPlaceholders.year}
                labelText={dropdownLabels.year}
                valueKey="year"
                labelKey="year"
                value={year}
                onChange={this.setYear}
                onFocus={this.props.onFocus}
                className={dropdownClassName}
                labelClassName={labelClassName}
                data-tracking-id={dropdownTrackingIds.year}
                id={`ymms-select-year${suffix}${this.uniqId}`}
                isStyled={useStyledSelects}
                isLabelHidden={hideLabels}
                pubStates={pubStates}
                ariaDescribedBy="yearError"
                ariaInvalid={displayError.year}
                disabled={disabled}
              />
              {displayError.year && <FieldError id="yearError" classes="mb-0 mt-0_25" error={errorMessages.year} />}
            </FormGroup>
          </Col>

          <Col {...dropdownColSize.make} className={colClassName}>
            <FormGroup
              className={classnames(formGroupClassName, {
                'has-danger': isFormControlErrorHighlighted && displayError.make,
              })}
            >
              <MakeDropdown
                year={year}
                name="select-make"
                toggle={dropdownPlaceholders.make}
                labelText={dropdownLabels.make}
                valueKey="niceName"
                labelKey="name"
                onChange={this.setMake}
                value={make}
                className={dropdownClassName}
                labelClassName={labelClassName}
                data-tracking-id={dropdownTrackingIds.make}
                id={`ymms-select-make${suffix}${this.uniqId}`}
                isStyled={useStyledSelects}
                isLabelHidden={hideLabels}
                pubStates={pubStates}
                ariaDescribedBy="makeError"
                ariaInvalid={displayError.make}
                disabled={disabled}
              />
              {displayError.make && <FieldError id="makeError" classes="mb-0 mt-0_25" error={errorMessages.make} />}
            </FormGroup>
          </Col>

          {hasModelsDropdown && (
            <Col {...dropdownColSize.model} className={colClassName}>
              <FormGroup
                className={classnames(formGroupClassName, {
                  'has-danger': isFormControlErrorHighlighted && displayError.model,
                })}
              >
                <ModelDropdown
                  make={make}
                  year={year}
                  name="select-model"
                  toggle={dropdownPlaceholders.model}
                  labelText={dropdownLabels.model}
                  valueKey="niceId"
                  labelKey="name"
                  value={model}
                  onChange={this.setModel}
                  className={dropdownClassName}
                  labelClassName={labelClassName}
                  data-tracking-id={dropdownTrackingIds.model}
                  id={`ymms-select-model${suffix}${this.uniqId}`}
                  isStyled={useStyledSelects}
                  isLabelHidden={hideLabels}
                  pubStates={pubStates}
                  ariaDescribedBy="modelError"
                  ariaInvalid={displayError.model}
                  disabled={disabled}
                />
                {displayError.model && (
                  <FieldError id="modelError" classes="mb-0 mt-0_25" error={errorMessages.model} />
                )}
              </FormGroup>
            </Col>
          )}

          {hasSubmodelsDropdown && (
            <Col {...dropdownColSize.submodel} className={colClassName}>
              <FormGroup
                className={classnames(formGroupClassName, {
                  'has-danger': isFormControlErrorHighlighted && displayError.submodel,
                })}
              >
                <SubmodelDropdown
                  make={make}
                  year={year}
                  name="select-submodel"
                  toggle={dropdownPlaceholders.submodel}
                  labelText={dropdownLabels.submodel}
                  valueKey="name"
                  labelKey="name"
                  value={submodel && submodel.name}
                  onChange={this.setSubmodel}
                  className={dropdownClassName}
                  labelClassName={labelClassName}
                  data-tracking-id={dropdownTrackingIds.submodel}
                  id={`ymms-select-submodel${suffix}${this.uniqId}`}
                  isStyled={useStyledSelects}
                  isLabelHidden={hideLabels}
                  disabled={disabled}
                />
                {displayError.submodel && (
                  <FieldError id="submodelError" classes="mb-0 mt-0_25" error={errorMessages.submodel} />
                )}
              </FormGroup>
            </Col>
          )}

          {hasStylesDropdown && (
            <Col {...dropdownColSize.style} className={colClassName}>
              <FormGroup
                className={classnames(formGroupClassName, {
                  'has-danger': isFormControlErrorHighlighted && displayError.style,
                })}
              >
                <StylesDropDownComponent
                  make={make}
                  model={model}
                  submodel={submodel}
                  year={year}
                  withoutSubmodel={!hasSubmodelsDropdown}
                  name="select-style"
                  toggle={dropdownPlaceholders.style}
                  labelText={dropdownLabels.style}
                  valueKey="name"
                  labelKey="name"
                  onChange={this.setStyle}
                  value={style && style.name}
                  className={dropdownClassName}
                  labelClassName={labelClassName}
                  data-tracking-id={dropdownTrackingIds.style}
                  id={`ymms-select-style${suffix}${this.uniqId}`}
                  isStyled={useStyledSelects}
                  isLabelHidden={hideLabels}
                  disabled={disabled}
                />
                {displayError.style && (
                  <FieldError id="styleError" classes="mb-0 mt-0_25" error={errorMessages.style} />
                )}
              </FormGroup>
            </Col>
          )}
        </Row>

        {typeof children === 'function' ? children({ make, model, submodel, year, style }) : children}
      </Form>
    );
  }
}
